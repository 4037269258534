import cssStyleContext from '/cssGlobal/style-context.css'

const contrastingStyleContexts = {
  white: cssStyleContext.contextBlack,
  black: cssStyleContext.contextWhite,
  sand: cssStyleContext.contextWhite,
  clay: cssStyleContext.contextWhite,
  gray: cssStyleContext.contextWhite
}

const StyleContext = React.createContext(null)

export function StyleContextProvider({ styleContext = cssStyleContext.contextWhite, children }) {
  return (
    <StyleContext.Provider value={styleContext}>
      {children}
    </StyleContext.Provider>
  )
}

export function useStyleContext() {
  return React.useContext(StyleContext)
}

export function useContrastingStyle(overrides = {}) {
  const colors = Object.entries(overrides).filter(([x]) => x !== 'theme')
  const styleContextValues = Object.values(cssStyleContext)

  if (colors.length !== 0) {
    colors.forEach(([key, value]) => {
      const hasKey = styleContextValues.includes(key)
      const hasValue = styleContextValues.includes(value)

      if (!hasKey || !hasValue) {
        throw new Error(`
          StyleContext's useContrastingStyle hook does not accept '${value}'.
          It does not seem to exist in style-contexts.css.
        `)
      }
    })
  }

  const color = React.useContext(StyleContext)
  const object = { ...contrastingStyleContexts, ...overrides }

  return object[overrides?.theme ?? color]
}
