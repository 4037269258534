import { reportClientError } from '/machinery/reportClientError'
import { unixTimeStampToFormattedDate } from '/machinery/dayjs'

export function pushToDataLayer(data) {
  window.dataLayer = window.dataLayer || [];
  [].concat(data).forEach(x => {
    try {
      if (!x) throw new Error(`No data given to pushToDataLayer function`)
      window.dataLayer.push(removeUndefinedValues(x))
    } catch (e) {
      reportClientError(e)
    }
  })
}

export function trackInteraction({ title, action, type, index = undefined, extraInteractionData = {}, extraMetaData = {} } ) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      interaction: {
        title,
        action,
        type,
        ...(index && { index }),
        ...extraInteractionData
      },
      ...extraMetaData,
    }
  })
}

export function refToDataXLink(ref, { prefix = 'link' } = {}) {
  if (!ref?._type) return prefix

  const { _type, atsConnectionSlug } = ref
  const type = ['homeCHO', 'homeLHO', 'homeRetailOnly'].includes(_type) ? 'home' : _type

  return `${prefix}-to-${[type, atsConnectionSlug].filter(Boolean).join('-')}`
}

/**
 * Has to be fired when visiting a job detail page
 */
export function trackJobDetailView(job) {
  return pushToDataLayer({
    event: 'view_item',
    metadata: { job: mapJobForDataLayer(job) }
  })
}

export function mapJobForDataLayer(job) {
  const { min: minHours, max: maxHours } = job.hoursPerWeek || {}

  /**
   * If something changes in this function, also change it in:
   * createFirebaseRules.js : candidateTracking
   * Form.js : getJobtrackingData
   * canidate-tracking-service.js : handleApplicationStatusUpdate
   */

  return {
    id: job.jobAdId,
    jobid: job.jobId,
    refnumber: job.refNumber,
    index: job.trackingIndex,
    title: job.title,
    brand: 'rituals',
    type: job.locationType,
    datestart: unixTimeStampToFormattedDate(job.createdDate),
    language: job.languageData.code,
    experience: job.experienceLevel,
    location: {
      country: job.country,
      city: job.city,
      type: job.locationTypeLabel,
      ...(job.postalCode && { postalcode: job.postalCode }),
      ...(job.address && { address: job.address }),
      locationName: job.locationName,
      lat: job.lonLat.lat,
      long: job.lonLat.lon,
    },
    ...(job.shopRole?.label && { role: job.shopRole.label }),
    ...(job.officeDepartment?.label && { department: job.officeDepartment.label }),
    ...(job.contractType?.label && { typeofemployment: job.contractType.label }),
    ...(minHours && { hoursperweekmin: minHours }),
    ...((maxHours || minHours) && { hoursperweekmax: maxHours ?? minHours }),
  }
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}

export function trackFilter({
  resultcount,
  searchterm,
  locationType,
  filterName,
}) {
  return pushToDataLayer({
    event: 'jobs_filtered',
    metadata: {
      filter: {
        resultcount,
        searchterm,
        locationType,
        filterName,
      },
    }
  })
}
