import groq from 'groq'
import { wordCount } from '/machinery/tracking/metadata'
import { parameterExtraction, docWithSeo } from '@kaliber/sanity-routing/sanity'
import { countrySettings } from '/i18n/countrySettings'
import { unixTimeStampToFormattedDate, dayjs } from '/machinery/dayjs'
import { mapJobForDataLayer } from '/machinery/tracking/pushToDataLayer'
import { snippets } from './groqSnippets'
import { installedLanguages } from './installedLanguageLookup'
import { formatHoursPerWeek } from './domain/formatHoursPerWeek'
import { determineCountry, determineLanguage, determineLocale } from './i18n/utils'

import cssStyleContext from '/cssGlobal/style-context.css'
import { toPlainText } from '@portabletext/react'

const { extract, slug } = parameterExtraction

function language(document) {
  return { language: determineLocale(document) }
}

export function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language: locale } }) => {
          const country = determineCountry(locale)
          return {
            menu: snippets.countrySingletonDocument(
              `menu`,
              snippets.projections.menu,
              { language: installedLanguages[locale], country }
            ),
            settings: groq`*[_id == 'settings'] | order(_updatedAt desc) [0] {
              shareImage,
              organisation,
            }`,
            footerMenu: snippets.countrySingletonDocument(
              `footerMenu`,
              snippets.projections.footerMenu,
              { language: installedLanguages[locale], country }
            ),
          }
        },
        fetch: {
          userInfo: requestHandlers => requestHandlers.getUserInfo,
        },
        derived: ({ data, params: { language: locale } }) => {
          const languageShort = determineLanguage(locale)
          const country = determineCountry(locale)

          return {
            lateNight: isLateNight(),
            country,
            dataLayer: {
              metadata: {
                content: {
                  language: languageShort,
                  ...(data.doc?._id && { id: data.doc?._id }),
                },
                platform: {
                  buildnumber: process.env.BUILD_NUMBER || Date.now()
                }
              }
            }
          }
        },
      },

      home: {
        data: {
          groq: ({ params: { language: locale } }) => {
            const country = determineCountry(locale)
            const { officeType } = countrySettings[country]

            return {
              doc: snippets.localSingletonDocument(
                `home${officeType}`,
                snippets.projections[`home${officeType}`],
                { language: installedLanguages[locale], country }
              ),
            }
          },
          fetch: {
            relatedJobs: requestHandlers => requestHandlers.latestJobs
          },
          derived: ({ data, derived }) => ({
            ...deriveDocumentInformation({ data, derived }, {
              type: 'home',
            }),
            mainMenuTheme: cssStyleContext.contextWhite
          }),
          extractParams: {
            homeCHO: extract(language),
            homeLHO: extract(language),
            homeRetailOnly: extract(language),
          }
        }
      },

      jobs: {
        data: {
          groq: ({ params: { language: locale } }) => {
            const country = determineCountry(locale)
            const { officeType } = countrySettings[country]

            return {
              doc: snippets.localSingletonDocument(
                'jobsOverview',
                snippets.projections.jobsOverview,
                { language: installedLanguages[locale], country }
              ),
              shopRoles: snippets.multipleGlobalDocuments(
                'atsTypeOfRole',
                snippets.projections.shopRolesFilterList,
                { language: installedLanguages[locale], country }
              ),
              ...(officeType !== 'RetailOnly' && {
                officeDepartments: snippets.multipleGlobalDocumentsFilterConnectionCountries(
                  'atsDepartment',
                  snippets.projections.officeDepartmentsFilterList,
                  { language: installedLanguages[locale], country }
                ),
              })
            }
          },
          fetch: {
            initialJobsAndFilters: requestHandlers => requestHandlers.initialJobsAndFilters,
            host: requestHandlers => requestHandlers.getHostFromConfig,
          },
          derived: ({ data, derived, route }) => ({
            ...deriveDocumentInformation({ data, derived }, {
              title: x => x.title,
              canonicalUrl: data.doc && `${data.host}${route(extract(language)(data.doc))}`,
              type: 'job-overview',
            }),
            mainMenuTheme: cssStyleContext.contextBlack
          }),
          extractParams: {
            jobsOverview: extract(language)
          }
        },

        detail: {
          data: {
            fetch: {
              jobAndAdditionalSanityData: requestHandlers => requestHandlers.jobAndAdditionalSanityData,
            },
            derived: ({ data, derived }) => ({
              ...data.jobAndAdditionalSanityData?.job && derivedJobInformation({ data, derived }),
              mainMenuTheme: cssStyleContext.contextWhite
            }),
          },
        }
      },

      workWithHeartAndSoul: {
        page: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  `workWithHeartAndSoulPage`,
                  snippets.projections.workWithHeartAndSoulPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'evp',
              }),
              mainMenuTheme: cssStyleContext.contextWhite
            }),
            extractParams: { workWithHeartAndSoulPage: extract(language) }
          }
        },
        timeline: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  `timelinePage`,
                  snippets.projections.timelinePage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              latestJobs: requestHandlers => requestHandlers.latestJobs
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                description: x => x.introduction,
                type: 'timeline',
              }),
              mainMenuTheme: cssStyleContext.contextWhite
            }),
            extractParams: { timelinePage: extract(language) }
          }
        },
        about: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  `aboutPage`,
                  snippets.projections.aboutPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'about',
              }),
              mainMenuTheme: cssStyleContext.contextWhite
            }),
            extractParams: { aboutPage: extract(language) }
          }
        },
        childPage: {
          data: {
            groq: ({ params: { language: locale, slug } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.document(
                  `workWithHeartAndSoulChildPage`,
                  snippets.projections.workWithHeartAndSoulChildPage,
                  { language: installedLanguages[locale], slug, country }
                ),
              }
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'evp-child-page'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
            extractParams: { workWithHeartAndSoulChildPage: extract(language, slug) }
          }
        }
      },

      shops: {
        page: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'shopsPage',
                  snippets.projections.shopsPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              relatedJobs: requestHandlers => requestHandlers.latestShopJobs
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                description: x => toPlainText(x.introduction ?? []),
                type: 'shop'
              }),
              mainMenuTheme: cssStyleContext.contextWhite
            }),
            extractParams: { shopsPage: extract(language) },
          },
        },
        growth: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'shopsGrowthPage',
                  snippets.projections.shopsGrowthPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              jobs: requestHandlers => requestHandlers.shopJobs,
            },
            extractParams: { shopsGrowthPage: extract(language) },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'shop-growth'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
          },
        },
        benefits: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'shopsBenefitsPage',
                  snippets.projections.shopsBenefitsPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              jobs: requestHandlers => requestHandlers.shopJobs,
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'shop-benefits'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
            extractParams: { shopsBenefitsPage: extract(language) },
          },
        },
        faq: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)
              return {
                doc: snippets.localSingletonDocument(
                  'shopsFAQPage',
                  snippets.projections.shopsFAQPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              jobs: requestHandlers => requestHandlers.shopJobs,
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'shops-faq'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
            extractParams: { shopsFAQPage: extract(language) },
          },
        },
        mindOasis: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'mindOasis',
                  snippets.projections.mindOasis,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              bodySpaMindOasisHouseOfRitualsJobs: requestHandlers => requestHandlers.bodySpaMindOasisHouseOfRitualsJobs,
            },
            extractParams: { mindOasis: extract(language) },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'shop-mind-oasis'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
          },
        },
        bodySpa: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'bodySpa',
                  snippets.projections.bodySpa,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              bodySpaMindOasisHouseOfRitualsJobs: requestHandlers => requestHandlers.bodySpaMindOasisHouseOfRitualsJobs,
            },
            extractParams: { bodySpa: extract(language) },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'shop-body-spa'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
          },
        },
        houseOfRituals: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'houseOfRituals',
                  snippets.projections.houseOfRituals,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              bodySpaMindOasisHouseOfRitualsJobs: requestHandlers => requestHandlers.bodySpaMindOasisHouseOfRitualsJobs,
            },
            extractParams: { houseOfRituals: extract(language) },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'shop-house-of-rituals'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
          },
        },
        role: {
          data: {
            groq: ({ params: { language: locale, slug } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.document(
                  'shopsRolePage',
                  snippets.projections.shopsRolePage,
                  { language: installedLanguages[locale], slug, country }
                ),
              }
            },
            fetch: {
              shopRoleJobs: requestHandlers => requestHandlers.shopRoleJobs,
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'shop-role'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
            extractParams: { shopsRolePage: extract(language, slug) },
          },
        },
      },

      offices: {
        page: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'officesPage',
                  snippets.projections.officesPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              relatedJobs: requestHandlers => requestHandlers.latestOfficeJobs,
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                description: x => toPlainText(x.introduction ?? []),
                type: 'office'
              }),
              mainMenuTheme: cssStyleContext.contextWhite
            }),
            extractParams: { officesPage: extract(language) },
          },
        },
        growth: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'officesGrowthPage',
                  snippets.projections.officesGrowthPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              jobs: requestHandlers => requestHandlers.officeJobs,
            },
            extractParams: { officesGrowthPage: extract(language) },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'office-growth'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
          },
        },
        benefits: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)
              return {
                doc: snippets.localSingletonDocument(
                  'officeBenefitsPage',
                  snippets.projections.officeBenefitsPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              jobs: requestHandlers => requestHandlers.officeJobs,
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'office-benefits'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
            extractParams: { officeBenefitsPage: extract(language) },
          },
        },
        interns: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)
              return {
                doc: snippets.localSingletonDocument(
                  'internsPage',
                  snippets.projections.internsPage,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            fetch: {
              internshipJobs: requestHandlers => requestHandlers.internshipJobs,
            },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                type: 'office-interns'
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }),
            extractParams: { internsPage: extract(language, slug) },
          },
        },
         faq: {
          page: {
            data: {
              groq: ({ params: { language: locale } }) => {
                const country = determineCountry(locale)
                return {
                  doc: snippets.localSingletonDocument(
                    'officeFAQPage',
                    snippets.projections.officeFAQPage,
                    { language: installedLanguages[locale], country }
                  ),
                }
              },
              fetch: {
                jobs: requestHandlers => requestHandlers.officeJobs,
              },
              derived: ({ data, derived }) => ({
                ...deriveDocumentInformation({ data, derived }, {
                  type: 'office-faq'
                }),
                mainMenuTheme: cssStyleContext.contextBlack
              }),
              extractParams: { officeFAQPage: extract(language) },
            },
          },
        },
        department: {
          page: {
            data: {
              groq: ({ params: { slug, language: locale } }) => {
                const country = determineCountry(locale)

                return {
                  doc: snippets.document(
                    'officesDepartmentPage',
                    snippets.projections.officesDepartmentPage,
                    { language: installedLanguages[locale], slug, country }
                  ),
                }
              },
              fetch: {
                officeDepartmentJobs: requestHandlers => requestHandlers.officeDepartmentJobs
              },
              extractParams: { officesDepartmentPage: extract(language, slug) },
              derived: ({ data, derived }) => ({
                ...deriveDocumentInformation({ data, derived }, {
                  type: 'office-department'
                }),
                mainMenuTheme: cssStyleContext.contextBlack
              }),
            },
          }
        }
      },

      lifeAtRituals: {
        page: {
          data: {
            groq: ({ params: { language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.localSingletonDocument(
                  'lifeAtRitualsPage',
                  snippets.projections.lifeAtRitualsPage,
                  { language: installedLanguages[locale], country }
                ),
                images: snippets.multipleCountryDocuments(
                  'lifeAtRitualsImage',
                  snippets.projections.lifeAtRitualsImage,
                  { language: installedLanguages[locale], country }
                ),
                videos: snippets.multipleCountryDocuments(
                  'lifeAtRitualsVideo',
                  snippets.projections.lifeAtRitualsVideo,
                  { language: installedLanguages[locale], country }
                ),
              }
            },
            extractParams: { lifeAtRitualsPage: extract(language) },
            derived: ({ data, derived }) => {
              const { images, videos } = data
              const imagesAndVideos = images.concat(videos).sort((a, b) => b._updatedAt.localeCompare(a._updatedAt))
              return {
                ...deriveDocumentInformation({ data, derived }, {
                  type: 'article-overview'
                }),
                imagesAndVideos,
                mainMenuTheme: cssStyleContext.contextWhite
              }
            },
          },
        },
        article: {
          data: {
            groq: ({ params: { slug, language: locale } }) => {
              const country = determineCountry(locale)

              return {
                doc: snippets.document(
                  'lifeAtRitualsArticle',
                  snippets.projections.lifeAtRitualsArticle,
                  { language: installedLanguages[locale], slug, country }
                ),
              }
            },
            extractParams: { lifeAtRitualsArticle: extract(language, slug) },
            derived: ({ data, derived }) => ({
              ...deriveDocumentInformation({ data, derived }, {
                description: x => x.introduction,
                type: 'article'
              }),
              mainMenuTheme: isLateNight()
                ? cssStyleContext.contextWhite
                : cssStyleContext.contextBlack
            }),
          },
        },
      },

      page: {
        data: {
          groq: ({ params: { slug, language: locale } }) => {
            const country = determineCountry(locale)

            return {
              doc: snippets.document(
                'page',
                snippets.projections.page,
                { language: installedLanguages[locale], slug, country }
              ),
            }
          },
          fetch: {
            enrichedContent: requestHandlers => requestHandlers.enrichContent,
          },
          derived: ({ data, derived }) => ({
            ...deriveDocumentInformation({ data, derived }, {
              type: 'flexible-page',
              wordCount: x => wordCount(x.content ?? []),
            }),
            mainMenuTheme: cssStyleContext.contextBlack
          }),
          extractParams: { page: extract(language, slug) },
        },
      },

      protectedPage: {
        data: {
          groq: ({ params: { slug, language: locale } }) => {
            const country = determineCountry(locale)

            return {
              doc: snippets.document(
                'protectedPage',
                snippets.projections.protectedPage,
                { language: installedLanguages[locale], slug, country }
              ),
            }
          },
          fetch: {
            enrichedContent: requestHandlers => requestHandlers.enrichContent,
          },
          derived: ({ data, derived }) => ({
            ...deriveDocumentInformation({ data, derived }, {
              type: 'flexible-page',
              wordCount: x => wordCount(x.content ?? []),
            }),
            mainMenuTheme: cssStyleContext.contextBlack
          }),
          extractParams: { protectedPage: extract(language, slug) },
        },
      },

      instore: {
        data: {
          groq: ({ params: { language: locale } }) => {
            const country = determineCountry(locale)

            return {
              doc: snippets.localSingletonDocument(
                'instorePage',
                snippets.projections.instorePage,
                { language: installedLanguages[locale], country }
              ),
            }
          },
          fetch: {
            shopData: requestHandlers => requestHandlers.getShopData
          },
          extractParams: {
            instorePage: extract(
              language,
              function title() {
                return { title: 'instore-sanity-preview' }
              },
              function stepId() {
                return { stepId: 'O98230' }
              },
            )
          },
          derived: ({ data, derived }) => ({
            ...deriveInstoreInformation({ data, derived }),
            mainMenuTheme: cssStyleContext.contextBlack
          }),
        },
      },
      instoreSearch: {
        data: {
          derived: ({ derived, params: { language: locale }  }) => {
            const country = determineCountry(locale)
            const type = 'instore-search'

            return {
              doc: {
                seo: {
                  advanced: {
                    allowIndex: false,
                    allowFollow: false,
                  }
                }
              },
              dataLayer: derivedDatalayer(derived, {
                content: { country, type }
              }),
              mainMenuTheme: cssStyleContext.contextBlack
            }
          }
        }
      },

      cookiePolicy: {
        data: {
          groq: ({ params: { language: locale } }) => {
            const country = determineCountry(locale)

            return {
              doc: snippets.localSingletonDocument(
                'cookiePolicy',
                snippets.projections.cookiePolicy,
                { language: installedLanguages[locale], country }
              )
            }
          },
          extractParams: { cookiePolicy: extract(language) },
          derived: ({ data, derived }) => ({
            ...deriveDocumentInformation({ data, derived }, {
              type: 'cookie-policy'
            }),
            mainMenuTheme: cssStyleContext.contextBlack
          })
        },
      },

      privacyPage: {
        data: {
          groq: ({ params: { language: locale } }) => {
            const country = determineCountry(locale)

            return {
              doc: snippets.localSingletonDocument(
                'privacyPage',
                snippets.projections.privacyPage,
                { language: installedLanguages[locale], country }
              )
            }
          },
          extractParams: { privacyPage: extract(language) },
          derived: ({ data, derived }) => ({
            ...deriveDocumentInformation({ data, derived }, {
              type: 'privacy-page',
            }),
            mainMenuTheme: cssStyleContext.contextBlack
          })
        },
      },

      notFound: {
        data: {
          groq: ({ params: { language: locale } }) => {
            return {
              notFound: snippets.globalSingletonDocument(
                `notFoundPage`,
                snippets.projections.notFoundPage,
                { language: installedLanguages[locale] }
              ),
            }
          }
        }
      }
    }
  }
}

function deriveDocumentInformation(
  {
    data,
    derived = { dataLayer: {}, country: null },
  },
  {
    title = x => x.title,
    description = _ => undefined,
    shareImage = _ => getHeroImageOrFallback(data),
    wordCount = _ => undefined,
    type = undefined,
    canonicalUrl = undefined,
  } = {}
) {
  const { doc } = data
  if (!doc) return

  const wordCountValue = wordCount(doc)

  return {
    doc: createUniqueSeoTitle(
      derived.country,
      docWithSeo(doc, {
        title: title(doc),
        description: description(doc),
        social: {
          shareImage: shareImage(doc)
        },
        ...(canonicalUrl && { advanced: { canonicalUrl } }),
      })
    ),
    dataLayer: derivedDatalayer(derived, {
      content: {
        title: title(doc),
        ...(doc._createdAt && { datepublished: unixTimeStampToFormattedDate(doc._createdAt) }),
        ...(wordCountValue && { wordcount: wordCountValue }),
        cmsLanguage: doc.language,
        country: doc.country,
        type,
      }
    }),
  }
}

function createUniqueSeoTitle(country, doc) {
  const countryString = country ? ` (${country})` : ''
  return {
    ...doc,
    seo: {
      ...doc.seo,
      title: `${doc.seo.title}${countryString}`
    }
  }
}

function deriveInstoreInformation({ data, derived }) {
  const { doc, shopData } = data
  const { heroImage, content } = doc ?? {}
  const { locationsData, canonicalUrl, jobs } = shopData ?? {}

  return {
    doc: createUniqueSeoTitle(
      derived.country,
      docWithSeo(doc, {
        title: locationsData.locationName,
        description: toPlainText(content ?? []),
        social: {
          shareImage: heroImage ?? data.settings?.shareImage,
        },
        ...(canonicalUrl && { advanced: { canonicalUrl } }),
      })
    ),
    locationsData,
    jobs,
    dataLayer: derivedDatalayer(derived, {
      content: {
        id: locationsData.stepId,
        title: locationsData.locationName,
        cmsLanguage: doc.language,
        country: doc.country,
        type: 'instore',
      },
      template: {
        type: 'instore'
      }
    }),
  }
}


function derivedJobInformation({ data, derived }) {
  const { job, canonicalUrl, sanityData } = data.jobAndAdditionalSanityData ?? {}
  const [departmentImage] = sanityData.images?.images ?? []

  return {
    doc: docWithSeo(data.jobAndAdditionalSanityData, {
      title: job.title,
      description: job.jobDescriptionPlain,
      social: {
        shareImage: departmentImage ?? data.settings?.shareImage,
      },
      advanced: {
        ...canonicalUrl && { canonicalUrl },
        ...job?.isInternal && { allowIndex: false },
      }
    }),
    dataLayer: derivedDatalayer(derived, {
      job: mapJobForDataLayer(job),
      content: {
        id: job.jobAdId,
        title: job.title,
        country: job.country,
        language: job.languageData.code,
        cmsLanguage: installedLanguages[job.language],
        dateupdated: unixTimeStampToFormattedDate(job.updatedDate),
        datecreated: unixTimeStampToFormattedDate(job.createdDate),
        datepublished: unixTimeStampToFormattedDate(job.releasedDate),
        type: 'job-detail',
      },
      template: {
        type: 'job-detail'
      }
    }),
    structuredData: [
      {
        type: 'JobPosting',
        data: {
          createdDate: unixTimeStampToFormattedDate(job.createdDate),
          title: job.title,
          id: job.jobAdId,
          hoursPerWeek: formatHoursPerWeek(job.hoursPerWeek),
          contractType: job.contractType?.label,
          description: job.jobDescriptionPlain,
          city: job.city,
          address: job.address,
          postalCode: job.postalCode,
          country: job.country,
        },
      },
    ],
  }
}

function getHeroImageOrFallback(data) {
  const { doc, settings } = data
  return doc.hero?.image || doc.hero?.collage?.images?.[0] || settings?.shareImage
}

function derivedDatalayer(derived, data) {
  return {
    ...derived.dataLayer,
    metadata: {
      ...derived.dataLayer?.metadata,
      content: {
        ...derived.dataLayer?.metadata?.content,
        ...(data.content && data.content),
      },
      ...(data.job  && { job: data.job }),
      ...(data.template  && { template: data.template }),
    }
  }
}

function isLateNight() {
  return dayjs().isAfter(dayjs().hour(18).minute(0)) || dayjs().isBefore(dayjs().hour(6).minute(0))
}
