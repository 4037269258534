export function determineCountry(locale) {
  const [, country] = determineLanguageAndCountry(locale)
  return country
}

export function determineLanguage(locale) {
  const [language] = determineLanguageAndCountry(locale)
  return language
}

export function determineLanguageAndCountry(locale) {
  return locale.split(/[-_]/)
}

export function determineLocale(document) {
  const [language] = document.language.split('_')
  if (!document.country) throw new Error(`Missing country in document ${document._id} (${document._type})`)
  return `${language}-${document.country}`
}
